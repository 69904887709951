import React, { useReducer, createContext } from 'react'

const initialState = {
    guide: 'on'
}

if (localStorage.getItem('guide')) {
    initialState.guide = localStorage.getItem('guide')
}

const GuideContext = createContext({
    guide: 'on',
    updateGuide: (guide) => {}
})

const settingsReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state,
                guide: action.payload
            }
        default:
            return state
    }
}

const GuideProvider = (props) => {
    const [state, dispatch] = useReducer(settingsReducer, initialState)

    const updateGuide = (guide) => {
        localStorage.setItem('guide', guide)
        dispatch({
            type: 'UPDATE',
            payload: guide
        })
    }

    return (
        <GuideContext.Provider
            value={{ guide: state.guide, updateGuide }}
            { ...props }
        />
    )
}

export { GuideContext, GuideProvider }