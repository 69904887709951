import React, { memo, useState, useContext, useEffect, Fragment, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/auth-context'
import { GuideContext } from '../../context/guide-context'
import { gql, useQuery, useMutation } from '@apollo/client'
import { CAMPAIGN_FIELDS } from '../../graphql/campaign-fragments'
import { USER_FIELDS } from '../../graphql/user-fragments'
import Card from '../../components/common/Card'
import CampaignForm from '../../components/campaigns/CampaignForm'
import Swal from 'sweetalert2'
import { showNotification } from '../../utils/notifications'
import { ToastContainer } from 'react-toastify'
import { useOnboardingTour } from '../../utils/onboarding-tour-hook'

const GET_USER = gql`
    ${USER_FIELDS}
    query Users($where: UserWhere) {
        users(where: $where) {
            ...UserFields
        }
    }`

const CREATE_CAMPAIGN = gql`
    ${CAMPAIGN_FIELDS}
    mutation CreateCampaign($campaignInput: CampaignInput, $userId: ID!) {
        createCampaign(campaignInput: $campaignInput, userId: $userId) {
            message
            ...CampaignFields
        }
    }`

const AddCampaign = memo(() => {
    const [updatedData, setUpdatedData] = useState({})
    const [errors, setError] = useState([])
    const navigate = useNavigate()
    const { user } = useContext(AuthContext)
    const { guide } = useContext(GuideContext)
    const { focus } = useOnboardingTour()
    const guidedRef = useRef(false)

    const { data } = useQuery(GET_USER, {
        variables: { where: { id: user.id } }
    })

    const createCampaignCallback = async () => {
         await createCampaign()
    }

    const updateData = (data) => {
        setUpdatedData(data)
    }

    const [createCampaign, mutation] = useMutation(CREATE_CAMPAIGN, {
        onError({ graphQLErrors}) {
            setError(graphQLErrors)
        },
        onCompleted: (data) => {
            navigate('/campaigns', {
                state: {
                    notification: {
                        status: data.createCampaign.message ? 'error' : 'success',
                        message: data.createCampaign.message
                            ? 'Campaign has been created successfully but payment was failed due to: ' + data.createCampaign.message + ' Please check your email for more details'
                            : 'Campaign has been created successfully!'
                    },
                    refetch: true
                }
            })
        },
        variables: {
            userId: user.id,
            campaignInput: {
                name: updatedData.name,
                userStatus: updatedData.userStatus,
                notes: updatedData.notes,
                purpose: updatedData.purpose,
                geolocation: updatedData.geolocation,
                address: updatedData.address,
                radius: parseInt(updatedData.radius),
                keywords: JSON.stringify(updatedData.keywords),
                user: {
                    email: updatedData.email,
                    username: updatedData.username,
                    password: updatedData.password,
                    firstName: updatedData.firstName,
                    lastName: updatedData.lastName,
                    timeZoneOffset: updatedData.timeZoneOffset
                }
            }
        }
    })

    useEffect(() => {
        if (data?.users[0].paymentMethodId === null) {
            Swal.fire({
                title: 'Payment Details Required',
                text: 'Please update Admin Profile and Payment Details before creating a Campaign',
                icon: 'warning',
                backdrop: `rgba(60,60,60,0.7)`,
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/profile')
                }
            })
        }
    }, [data, navigate])

    useEffect(() => {
        if (!guidedRef.current && guide === 'on') {
            showNotification(
                <div>
                    <p>Please enter the details for your campaign in the fields below.</p>
                    <p>If you need help, hover your mouse over the question mark icon to read the tooltip information.</p>
                </div>,
                'info',
                false,
                'Continue',
                () => focus('.campaign-step-1'),
                false
            )
            guidedRef.current = true
        }
    }, [guidedRef, guide])

    return(
        <Fragment>
            <ToastContainer />
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">
                            <i className="icon">
                                <svg className="icon-25" width="25" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.4" d="M19.9926 18.9532H14.2983C13.7427 18.9532 13.2909 19.4123 13.2909 19.9766C13.2909 20.5421 13.7427 21 14.2983 21H19.9926C20.5481 21 21 20.5421 21 19.9766C21 19.4123 20.5481 18.9532 19.9926 18.9532Z" fill="currentColor" />
                                    <path d="M10.309 6.90388L15.7049 11.264C15.835 11.3682 15.8573 11.5596 15.7557 11.6929L9.35874 20.0282C8.95662 20.5431 8.36402 20.8345 7.72908 20.8452L4.23696 20.8882C4.05071 20.8904 3.88775 20.7614 3.84542 20.5765L3.05175 17.1258C2.91419 16.4916 3.05175 15.8358 3.45388 15.3306L9.88256 6.95548C9.98627 6.82111 10.1778 6.79746 10.309 6.90388Z" fill="currentColor" />
                                    <path opacity="0.4" d="M18.1206 8.66544L17.0804 9.96401C16.9756 10.0962 16.7872 10.1177 16.6571 10.0124C15.3925 8.98901 12.1544 6.36285 11.2559 5.63509C11.1247 5.52759 11.1067 5.33625 11.2125 5.20295L12.2157 3.95706C13.1258 2.78534 14.7131 2.67784 15.9936 3.69906L17.4645 4.87078C18.0677 5.34377 18.4698 5.96726 18.6074 6.62299C18.7661 7.3443 18.5968 8.0527 18.1206 8.66544Z" fill="currentColor" />
                                </svg>
                            </i>{' '}
                            Create Campaign
                        </h4>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="new-user-info">
                        <CampaignForm
                            updateData={updateData}
                            callback={createCampaignCallback}
                            errors={errors}
                            mutation={mutation}
                            guidance={guide === 'on'}
                        />
                    </div>
                </Card.Body>
            </Card>
        </Fragment>
    )
})

AddCampaign.displayName ='AddCampaign'
export default AddCampaign