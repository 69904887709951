import React, { Fragment, useState } from 'react'
import { Table, Form, FormCheck, Button, Spinner, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import Card from '../../components/common/Card'
import { useForm } from '../../utils/form-hook'
import { showNotification } from '../../utils/notifications'
import ShowErrors from '../partials/ShowErrors'
import _ from 'lodash'

const UPDATE_PUBLISHER_CAMPAIGN_DATA = gql`
    mutation Mutation($publisherCampaignInput: PublisherCampaignInput) {
        updatePublisherCampaignData(publisherCampaignInput: $publisherCampaignInput) {
            name
            id
            landingPagesConnection {
                edges {
                    status
                    budget
                    node {
                        id
                        domain
                        title
                    }
                }
            }
            message
        }
    }`

const viewIcon = (
    <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" height="32">
    <path d="M21.4274 2.5783C20.9274 2.0673 20.1874 1.8783 19.4974 2.0783L3.40742 6.7273C2.67942 6.9293 2.16342 7.5063 2.02442 8.2383C1.88242 8.9843 2.37842 9.9323 3.02642 10.3283L8.05742 13.4003C8.57342 13.7163 9.23942 13.6373 9.66642 13.2093L15.4274 7.4483C15.7174 7.1473 16.1974 7.1473 16.4874 7.4483C16.7774 7.7373 16.7774 8.2083 16.4874 8.5083L10.7164 14.2693C10.2884 14.6973 10.2084 15.3613 10.5234 15.8783L13.5974 20.9283C13.9574 21.5273 14.5774 21.8683 15.2574 21.8683C15.3374 21.8683 15.4274 21.8683 15.5074 21.8573C16.2874 21.7583 16.9074 21.2273 17.1374 20.4773L21.9074 4.5083C22.1174 3.8283 21.9274 3.0883 21.4274 2.5783Z" fill="currentColor"></path>
    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M3.01049 16.8079C2.81849 16.8079 2.62649 16.7349 2.48049 16.5879C2.18749 16.2949 2.18749 15.8209 2.48049 15.5279L3.84549 14.1619C4.13849 13.8699 4.61349 13.8699 4.90649 14.1619C5.19849 14.4549 5.19849 14.9299 4.90649 15.2229L3.54049 16.5879C3.39449 16.7349 3.20249 16.8079 3.01049 16.8079ZM6.77169 18.0003C6.57969 18.0003 6.38769 17.9273 6.24169 17.7803C5.94869 17.4873 5.94869 17.0133 6.24169 16.7203L7.60669 15.3543C7.89969 15.0623 8.37469 15.0623 8.66769 15.3543C8.95969 15.6473 8.95969 16.1223 8.66769 16.4153L7.30169 17.7803C7.15569 17.9273 6.96369 18.0003 6.77169 18.0003ZM7.02539 21.5683C7.17139 21.7153 7.36339 21.7883 7.55539 21.7883C7.74739 21.7883 7.93939 21.7153 8.08539 21.5683L9.45139 20.2033C9.74339 19.9103 9.74339 19.4353 9.45139 19.1423C9.15839 18.8503 8.68339 18.8503 8.39039 19.1423L7.02539 20.5083C6.73239 20.8013 6.73239 21.2753 7.02539 21.5683Z" fill="currentColor"></path>
    </svg>
)

const previewIcon = (
    <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" height="32">
        <path opacity="0.4" d="M22 12.0048C22 17.5137 17.5116 22 12 22C6.48842 22 2 17.5137 2 12.0048C2 6.48625 6.48842 2 12 2C17.5116 2 22 6.48625 22 12.0048Z" fill="currentColor"></path>
        <path d="M16 12.0049C16 12.2576 15.9205 12.5113 15.7614 12.7145C15.7315 12.7543 15.5923 12.9186 15.483 13.0255L15.4233 13.0838C14.5881 13.9694 12.5099 15.3011 11.456 15.7278C11.456 15.7375 10.8295 15.9913 10.5312 16H10.4915C10.0341 16 9.60653 15.7482 9.38778 15.34C9.26847 15.1154 9.15909 14.4642 9.14915 14.4554C9.05966 13.8712 9 12.9769 9 11.9951C9 10.9657 9.05966 10.0316 9.16903 9.45808C9.16903 9.44836 9.27841 8.92345 9.34801 8.74848C9.45739 8.49672 9.65625 8.2819 9.90483 8.14581C10.1037 8.04957 10.3125 8 10.5312 8C10.7599 8.01069 11.1875 8.15553 11.3565 8.22357C12.4702 8.65128 14.598 10.051 15.4134 10.9064C15.5526 11.0425 15.7017 11.2087 15.7415 11.2467C15.9105 11.4605 16 11.723 16 12.0049Z" fill="currentColor"></path>
    </svg>
)

const CampaignPublisherForm = ({data, ads, campaignId, callback}) => {
    const [errors, setError] = useState([])
    const [validated, setValidated] = useState(false)
    const [showPreview, setShowPreview] = useState(false)
    const [preview, setPreview] = useState(null)
    const navigate = useNavigate()

    const onClickViewButton = (domain) => {
        const url = `https://${domain}.${process.env.REACT_APP_LANDING_PAGE_DOMAIN}`
        window.open(url, '_blank', 'noreferrer')
    }

    const save = async () => {
        await updatePublisher()
    }

    const adIds = data.landingPagesConnection.edges.map((item) => item.node.id)
    const newAds = ads.filter((item) => {
        return !adIds.includes(item.id)
    })
    const adList = JSON.parse(JSON.stringify(data.landingPagesConnection.edges)).filter((item) => {
        return item.node.status === 'ACTIVE'
    })

    _.each(newAds, (ad) => {
        adList.push({
            budget: 0,
            status: 'INACTIVE',
            node: ad,
            userAdStatus: 'INACTIVE'
        })
    })

    const initialValues = _.map(adList, (value) => ({
        landingPageId: value.node.id,
        status: value.status,
        budget: value.budget,
        userAdStatus: value.userAdStatus ? value.userAdStatus : 'INACTIVE'
    }))

    const { values, setValue } = useForm(save, initialValues)

    const [updatePublisher, mutation] = useMutation(UPDATE_PUBLISHER_CAMPAIGN_DATA, {
        onError({ graphQLErrors}) {
            setError(graphQLErrors)
        },
        onCompleted: (data) => {
            setValidated(false)
            callback()
            if (data.updatePublisherCampaignData.message.length > 0) {
                const messages = data.updatePublisherCampaignData.message.split('|')
                const message = messages.join('\n')
                showNotification(message, 'error')
            } else {
                showNotification(
                    'Media Buying data has been updated successfully',
                    'success'
                )
            }
        },
        variables: {
            publisherCampaignInput: {
                publisherId: data.id,
                campaignId: campaignId,
                data: values
            }
        }
    })

    const onChange = (event) => {
        const name = event.target.name.match(/^([a-zA-Z]+)/)[0]
        const index = event.target.name.match(/\[(\d+)\]/)[1]

        setValue(prevState => {
            const updatedValues = [...prevState]
            updatedValues[index] = {
                ...updatedValues[index],
                [name]: parseInt(event.target.value)
            }
            return updatedValues
        })
    }

    const onChangeStatus = (event) => {
        const name = event.target.name.match(/^([a-zA-Z]+)/)[0]
        const index = event.target.name.match(/\[(\d+)\]/)[1]

        setValue(prevState => {
            const updatedValues = [...prevState]
            updatedValues[index] = {
                ...updatedValues[index],
                [name]: prevState[index][name] === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE'
            }
            return updatedValues
        })
    }

    const onSubmit = (event) => {
        event.preventDefault()
        event.stopPropagation()

        validateForm(event)
    }

    const validateForm = (event) => {
        const form = event.target.form

        if (form.checkValidity()) {
            setError([])
            save()
        } else {
            setValidated(true)
        }
    }

    const showAdStatus = (ad) => {
        switch (ad.adStatus) {
            case 'IN_PROCESS':
                return (
                    <span className="badge bg-info">Processing</span>
                )
            case 'PAUSED':
                return (
                    <span className="badge bg-primary">Paused</span>
                )
            case 'PENDING_REVIEW':
                return (
                    <span className="badge bg-warning">Review</span>
                )
            case 'ACTIVE':
                return (
                    <span className="badge bg-success">Active</span>
                )
            case 'DISAPPROVED':
                return (
                    <span className="badge bg-danger">Not Approved</span>
                )
            case 'WITH_ISSUES':
                return (
                    <span className="badge bg-danger">Error</span>
                )
        }
    }

    const onClickPreviewButton = (ad) => {
        setPreview(ad.preview)
        setShowPreview(true)
    }

    return (
        <Fragment>
            <Form noValidate validated={validated}>
                <Card className="border shadow-none">
                    <Card.Body className="p-0 pt-3">
                        <div className="table-responsive">
                            <Table className="table table-borderless3 mb-0 table-striped">
                                <thead>
                                <tr>
                                    <th width="200px">Off / On</th>
                                    <th width="50px"></th>
                                    <th>Creative</th>
                                    <th>Daily Budget ($)</th>
                                    <th>Status</th>
                                    <th>Preview</th>
                                    <th>Paused / Active</th>
                                </tr>
                                </thead>
                                <tbody>
                                {adList.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Form.Check className="form-switch form-check-inline">
                                                <FormCheck.Input type="checkbox" id={'switch-' + index}
                                                                 style={{height: '1.5em', width: '3em'}}
                                                                 name={'status[' + index + ']'}
                                                                 checked={values[index].status === 'ACTIVE'}
                                                                 onChange={onChangeStatus}/>
                                                <FormCheck.Label className="pl-10" htmlFor={'switch-' + index}>
                                                    {values[index].status === 'ACTIVE' ? 'Active' : 'Not Active'}
                                                </FormCheck.Label>
                                            </Form.Check>
                                        </td>
                                        <td>
                                            <a className="btn btn-icon btn-success"
                                               onClick={() => onClickViewButton(item.node.domain)}>
                                                <span className="btn-inner">{viewIcon}</span>
                                            </a>
                                        </td>
                                        <td>{item.node.title}</td>
                                        <td>
                                            <Form.Control required type="number" min="0" name={'budget[' + index + ']'}
                                                          value={values[index].budget}
                                                          min={2}
                                                          onChange={onChange}
                                            ></Form.Control>
                                        </td>
                                        <td>{showAdStatus(item)}</td>
                                        <td>{item.preview && (
                                            <a className="btn btn-icon btn-primary"
                                               onClick={() => onClickPreviewButton(item)}>
                                                <span className="btn-inner">{previewIcon}</span>
                                            </a>
                                        )}
                                        </td>
                                        <td>{values[index].status === 'ACTIVE' && item.adStatus && (
                                            <Form.Check className="form-switch form-check-inline">
                                                <FormCheck.Input type="checkbox" id={'switch-user-' + index}
                                                                 style={{height: '1.5em', width: '3em'}}
                                                                 name={'userAdStatus[' + index + ']'}
                                                                 checked={values[index].userAdStatus === 'ACTIVE'}
                                                                 onChange={onChangeStatus}/>
                                                <FormCheck.Label className="pl-10" htmlFor={'switch-user-' + index}>
                                                    {values[index].userAdStatus === 'ACTIVE' ? 'Active' : 'Paused'}
                                                </FormCheck.Label>
                                            </Form.Check>
                                        )}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
                <div className="pb-3 pt-3">
                    <ShowErrors errors={errors}/>
                </div>
                <Button
                    type="button"
                    variant="btn btn-primary"
                    onClick={onSubmit}
                    disabled={mutation.loading}
                    className="campaign-step-13"
                >
                    {mutation.loading ? (
                        <Fragment>
                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/>
                            {' '}Updating...
                        </Fragment>
                    ) : (
                        <Fragment>Update</Fragment>
                    )}
                </Button>{' '}
                <Button
                    type="button"
                    variant="btn btn-danger"
                    onClick={() => {
                        navigate('/campaigns')
                    }}
                    disabled={mutation.loading}
                >
                    Cancel
                </Button>
            </Form>
            <Modal show={showPreview} onHide={() => setShowPreview(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Ad Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{__html: preview}} style={{textAlign: 'center'}}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowPreview(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default CampaignPublisherForm