import React, { memo, useState, Fragment, useEffect } from 'react'
import { Row, Button, Form, Modal, Spinner } from 'react-bootstrap'
import { gql, useMutation, useQuery } from '@apollo/client'
import Card from '../common/Card'
import Loader from '../../layouts/components/Loader'
import DataTable from '../common/DataTable'
import { useForm } from '../../utils/form-hook'
import { showNotification } from '../../utils/notifications'
import _ from 'lodash'

const GET_PAYMENTS = gql`
    query Payments {
        payments {
            amount
            createdAt
            id
            invoice
            status
        }
    }
`

const MAKE_PAYMENT = gql`
    mutation MakePayment($amount: Float, $paymentId: String) {
        makePayment(amount: $amount, paymentId: $paymentId) {
            amount
            createdAt
            id
            invoice
            status
        }
    }
`

const invoiceIcon = `<svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" height="32">
    <path opacity="0.4" d="M18.8089 9.021C18.3574 9.021 17.7594 9.011 17.0149 9.011C15.199 9.011 13.7059 7.508 13.7059 5.675V2.459C13.7059 2.206 13.503 2 13.2525 2H7.96436C5.49604 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59109 22 8.1703 22H16.0455C18.5059 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.298 9.012 20.0465 9.013C19.6238 9.016 19.1168 9.021 18.8089 9.021Z" fill="currentColor"></path>
    <path opacity="0.4" d="M16.0842 2.56737C15.7852 2.25637 15.2632 2.47037 15.2632 2.90137V5.53837C15.2632 6.64437 16.1732 7.55437 17.2792 7.55437C17.9772 7.56237 18.9452 7.56437 19.7672 7.56237C20.1882 7.56137 20.4022 7.05837 20.1102 6.75437C19.0552 5.65737 17.1662 3.69137 16.0842 2.56737Z" fill="currentColor"></path>
    <path d="M15.1052 12.7088C14.8132 12.4198 14.3432 12.4178 14.0512 12.7108L12.4622 14.3078V9.48084C12.4622 9.06984 12.1282 8.73584 11.7172 8.73584C11.3062 8.73584 10.9722 9.06984 10.9722 9.48084V14.3078L9.38224 12.7108C9.09124 12.4178 8.62024 12.4198 8.32924 12.7088C8.03724 12.9988 8.03724 13.4698 8.32624 13.7618L11.1892 16.6378H11.1902C11.2582 16.7058 11.3392 16.7608 11.4302 16.7988C11.5202 16.8358 11.6182 16.8558 11.7172 16.8558C11.8172 16.8558 11.9152 16.8358 12.0052 16.7978C12.0942 16.7608 12.1752 16.7058 12.2432 16.6388L12.2452 16.6378L15.1072 13.7618C15.3972 13.4698 15.3972 12.9988 15.1052 12.7088Z" fill="currentColor"></path>
    </svg>`

const columns = [
    {
        title: 'Date',
        data: 'date'
    },
    {
        title: 'Amount',
        data: 'amount',
        render: (data) => {
            return new Intl.NumberFormat('en-AU', {
                style: 'currency',
                currency: 'AUD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(data)
        }
    },
    {
        title: 'Status',
        data: 'status',
        render: (data) => {
            return data.status === 'paid'
                ? (
                    '<span class="badge bg-primary">Paid</span>'
                ) : (
                    '<div class="flex align-items-center list-campaign-action"><a class="btn btn-sm btn-primary pay-icon" data-target="' + data.id + '">Pay Invoice</a></div>'
                )
        }
    },
    {
        title: 'Invoice',
        data: 'invoice',
        render: (data) => {
                return '<div class="flex align-items-center list-campaign-action"><a class="btn btn-icon btn-primary edit-icon" href="' + data + '"><span class="btn-inner">' +
                    invoiceIcon + '</span></a></div>'
            }
    }
]

const Payments = memo(({ onUpdateBudget }) => {
    const payments = useQuery(GET_PAYMENTS)
    const [paymentData, setPaymentData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [budget, setBudget] = useState(0)

    const [makePayment, mutation] = useMutation(MAKE_PAYMENT, {
        onError({ graphQLErrors}) {
            setShowModal(false)
            if (graphQLErrors[0]?.message) {
                showNotification(graphQLErrors[0].message,'error')
            }
            payments.refetch()
            console.log('ref')
        },
        onCompleted: () => {
            setShowModal(false)
            showNotification('Payment has been processed successfully','success')
            payments.refetch()
        }
    })

    const submitMakePayment = async () => {
        await makePayment({
            variables: {
                amount: parseFloat(values.amount)
            }
        })
    }

    const { onChange, onSubmit, values, setValue, validated, resetValidation } = useForm(submitMakePayment, {
        amount: '',
    })

    useEffect(() => {
        const array = []
        let total = 0
        if (payments.data) {
            payments.data.payments.forEach((payment) => {
                if (payment.status === 'paid') {
                    total += payment.amount
                }
                array.push({
                    id: payment.id,
                    amount: payment.amount,
                    status: {
                        status: payment.status,
                        id: payment.id
                    },
                    date: new Date(Date.parse(payment.createdAt)).toLocaleString('en-GB'),
                    invoice: payment.invoice
                })
            })
            setBudget(total)
            onUpdateBudget(total)
        }
        setPaymentData(array)
        setTimeout(() => {}, 10)
    }, [payments.data, setPaymentData])

    const onClickPayButton = async (event) => {
        const paymentId = event.currentTarget.getAttribute('data-target')
        await makePayment({
            variables: {
                paymentId: paymentId
            }
        })
    }

    const bindButtonEvents = () => {
        const payButtons = document.getElementsByClassName('pay-icon')

        _.each(payButtons, (element) => {
            element.removeEventListener('click', onClickPayButton)
            element.addEventListener('click', onClickPayButton)
        })
    }

    const openModal = () => {
        setShowModal(true)
        setTimeout(() => {
            const input = document.getElementById('amount')
            if (input) {
                input.focus()
            }
        }, 100)
    }

    return (
        <Fragment>
            <Card>
                <Card.Header className="align-items-center flex-wrap">
                    <div className="header-title">
                        <h4 className="card-title">
                            <i className="icon">
                                <svg width="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-25">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.9964 8.37513H17.7618C15.7911 8.37859 14.1947 9.93514 14.1911 11.8566C14.1884 13.7823 15.7867 15.3458 17.7618 15.3484H22V15.6543C22 19.0136 19.9636 21 16.5173 21H7.48356C4.03644 21 2 19.0136 2 15.6543V8.33786C2 4.97862 4.03644 3 7.48356 3H16.5138C19.96 3 21.9964 4.97862 21.9964 8.33786V8.37513ZM6.73956 8.36733H12.3796H12.3831H12.3902C12.8124 8.36559 13.1538 8.03019 13.152 7.61765C13.1502 7.20598 12.8053 6.87318 12.3831 6.87491H6.73956C6.32 6.87664 5.97956 7.20858 5.97778 7.61852C5.976 8.03019 6.31733 8.36559 6.73956 8.36733Z" fill="currentColor"></path>
                                    <path opacity="0.4" d="M16.0374 12.2966C16.2465 13.2478 17.0805 13.917 18.0326 13.8996H21.2825C21.6787 13.8996 22 13.5715 22 13.166V10.6344C21.9991 10.2297 21.6787 9.90077 21.2825 9.8999H17.9561C16.8731 9.90338 15.9983 10.8024 16 11.9102C16 12.0398 16.0128 12.1695 16.0374 12.2966Z" fill="currentColor"></path>
                                    <circle cx="18" cy="11.8999" r="1" fill="currentColor"></circle>
                                </svg>
                            </i>{' '}
                            Payments - Total Budget: { new Intl.NumberFormat('en-AU', {
                            style: 'currency',
                            currency: 'AUD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        }).format(budget)}
                        </h4>
                    </div>
                    <Button
                        variant="success"
                        id="make-payment"
                        size="sm"
                        onClick={() => openModal()}
                    >
                        <svg
                            width="32"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon-24"
                            height="32"
                        >
                            <path
                                opacity="0.4"
                                d="M16.6667 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0622 3.92 22 7.33333 22H16.6667C20.0711 22 22 20.0622 22 16.6667V7.33333C22 3.92889 20.0711 2 16.6667 2Z"
                                fill="currentColor"
                            ></path>
                            <path
                                d="M15.3205 12.7083H12.7495V15.257C12.7495 15.6673 12.4139 16 12 16C11.5861 16 11.2505 15.6673 11.2505 15.257V12.7083H8.67955C8.29342 12.6687 8 12.3461 8 11.9613C8 11.5765 8.29342 11.2539 8.67955 11.2143H11.2424V8.67365C11.2824 8.29088 11.6078 8 11.996 8C12.3842 8 12.7095 8.29088 12.7495 8.67365V11.2143H15.3205C15.7066 11.2539 16 11.5765 16 11.9613C16 12.3461 15.7066 12.6687 15.3205 12.7083Z"
                                fill="currentColor"
                            ></path>
                        </svg>{' '}
                        Make Payment
                    </Button>
                </Card.Header>
                <Card.Body className="px-0 loader-container">
                    { payments.loading ? (
                        <Loader fullScreen={false} />
                    ) : (
                        <Fragment>
                            {mutation.loading && (
                                <div className="loader-overlay">
                                    <Loader fullScreen={false}/>
                                </div>
                            )}
                            <div className={`table-responsive ${mutation.loading ? 'inactive' : ''}`}>
                                <DataTable
                                    data={paymentData}
                                    className="table-striped"
                                    columns={columns}
                                    defaultSort={{columnIndex: 0, order: 'desc'}}
                                    onDraw={bindButtonEvents}
                                />
                            </div>
                        </Fragment>
                    )}
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Form noValidate validated={validated}>
                    <Modal.Header closeButton>
                        <Modal.Title as="h5">Make Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row>
                                <Form.Group className="col-sm-12 form-group">
                                    <Form.Label htmlFor="startDate">Amount</Form.Label>
                                    <Form.Control type="number" id="amount" name="amount" value={values.amount}
                                                  onChange={onChange}></Form.Control>
                                </Form.Group>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            type="button"
                            variant="btn btn-primary"
                            onClick={onSubmit}
                            disabled={mutation.loading}
                            className="lp-step-17"
                        >
                            {mutation.loading ? (
                                <Fragment>
                                    <Spinner as="span" animation="grow" size="sm" role="status"
                                             aria-hidden="true"/>
                                    {' '}Paying...
                                </Fragment>
                            ) : (
                                <Fragment>Pay</Fragment>
                            )}
                        </Button>{' '}
                        <Button
                            type="button"
                            variant="btn btn-danger"
                            onClick={() => setShowModal(false)}
                            disabled={mutation.loading}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
})

export default Payments