import {STATUS} from "react-joyride";

export const useOnboardingTour = () => {
    const focus = (className) => {
        const element = document.querySelector(className)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })

            const input = element.querySelector('input, textarea, select')
            if (input) {
                input.focus()
            }
        }
    }

    const handleFocusTour = (data, steps) => {
        const { status, action, index, type } = data

        if (type === 'step:after' && action === 'close') {
            const currentStepTarget = document.querySelector(steps[index].target)
            const inputElement = currentStepTarget?.querySelector('input, textarea, select')
            if (inputElement) {
                inputElement.focus()
            }
        }
    }

    return { focus, handleFocusTour }
}