import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

export const showNotification = (message, type, timer = 5000, button = null, callback = null, closeButton = true) => {
    const MessageWithButton = () => (
        <div>
            {message}
            <div style={{
                textAlign: 'center',
            }}>
                {button && (
                    <button
                        style={{
                            padding: '5px 10px',
                            marginTop: '20px',
                            backgroundColor: '#1578bb',
                            color: 'white',
                            border: 'solid 1px #00589b',
                            borderRadius: '3px',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            toast.dismiss()
                            if (callback) {
                                callback()
                            }
                        }}
                    >
                        {button}
                    </button>
                )}

            </div>
        </div>
    );

    toast[type](<MessageWithButton />, {
        position: 'top-center',
        autoClose: type === 'success' ? timer : false,
        hideProgressBar: false,
        closeOnClick: closeButton,
        pauseOnHover: true,
        draggable: closeButton,
        progress: undefined,
        theme: 'colored',
        closeButton: closeButton,
        className: type === 'info' ? 'custom-toast' : ''
    })
}

export const askConfirmation = (text, confirmButtonText, callback, args = null) => {
    Swal.fire({
        title: 'Are you sure?',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        backdrop: `rgba(60,60,60,0.7)`,
        confirmButtonText: confirmButtonText
    }).then((result) => {
        if (result.isConfirmed) {
            callback(args)
        }
    })
}