import React, { memo, Fragment, useEffect, useState, useRef, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Row, Col, Button } from 'react-bootstrap'
import { GuideContext } from '../../context/guide-context'
import { CORE_LANDING_PAGE_FIELDS } from '../../graphql/landing-page-fragment'
import { CORE_CAMPAIGN_FIELDS } from '../../graphql/campaign-fragments'
import Card from '../../components/common/Card'
import DataTable from '../../components/common/DataTable'
import Notification from '../../components/partials/Notification'
import Loader from '../../layouts/components/Loader'
import { showNotification } from '../../utils/notifications'
import _ from 'lodash'

const GET_LANDING_PAGES = gql`
    ${CORE_LANDING_PAGE_FIELDS}
    query LandingPages($where: LandingPageWhere) {
        landingPages(where: $where) {
            ...CoreLandingPageFields
        }
    }
`

const GET_CAMPAIGN = gql`
    ${CORE_CAMPAIGN_FIELDS}
    query Campaigns($where: CampaignWhere) {
        campaigns(where: $where) {
            ...CoreCampaignFields
        }
    }
`

const editIcon = `<svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" height="32">
    <path opacity="0.4" d="M16.6643 21.9897H7.33488C5.88835 22.0796 4.46781 21.5781 3.3989 20.6011C2.4219 19.5312 1.92041 18.1107 2.01032 16.6652V7.33482C1.92041 5.88932 2.4209 4.46878 3.3979 3.39889C4.46781 2.42189 5.88835 1.92041 7.33488 2.01032H16.6643C18.1089 1.92041 19.5284 2.4209 20.5973 3.39789C21.5733 4.46878 22.0758 5.88832 21.9899 7.33482V16.6652C22.0788 18.1107 21.5783 19.5312 20.6013 20.6011C19.5314 21.5781 18.1109 22.0796 16.6643 21.9897Z" fill="currentColor"></path>
    <path d="M17.0545 10.3976L10.5018 16.9829C10.161 17.3146 9.7131 17.5 9.24574 17.5H6.95762C6.83105 17.5 6.71421 17.4512 6.62658 17.3634C6.53895 17.2756 6.5 17.1585 6.5 17.0317L6.55842 14.7195C6.56816 14.261 6.75315 13.8317 7.07446 13.5098L11.7189 8.8561C11.7967 8.77805 11.9331 8.77805 12.011 8.8561L13.6399 10.4785C13.747 10.5849 13.9028 10.6541 14.0683 10.6541C14.4286 10.6541 14.7109 10.3615 14.7109 10.0102C14.7109 9.83463 14.6428 9.67854 14.5357 9.56146C14.5065 9.52244 12.9554 7.97805 12.9554 7.97805C12.858 7.88049 12.858 7.71463 12.9554 7.61707L13.6078 6.95366C14.2114 6.34878 15.1851 6.34878 15.7888 6.95366L17.0545 8.22195C17.6485 8.81707 17.6485 9.79268 17.0545 10.3976Z" fill="currentColor"></path>
    </svg>`

const removeIcon = `<svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" height="32">
    <path opacity="0.4" d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z" fill="currentColor"></path>
    <path d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z" fill="currentColor"></path>
    </svg>`

const viewIcon = `<svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" height="32">
    <path d="M21.4274 2.5783C20.9274 2.0673 20.1874 1.8783 19.4974 2.0783L3.40742 6.7273C2.67942 6.9293 2.16342 7.5063 2.02442 8.2383C1.88242 8.9843 2.37842 9.9323 3.02642 10.3283L8.05742 13.4003C8.57342 13.7163 9.23942 13.6373 9.66642 13.2093L15.4274 7.4483C15.7174 7.1473 16.1974 7.1473 16.4874 7.4483C16.7774 7.7373 16.7774 8.2083 16.4874 8.5083L10.7164 14.2693C10.2884 14.6973 10.2084 15.3613 10.5234 15.8783L13.5974 20.9283C13.9574 21.5273 14.5774 21.8683 15.2574 21.8683C15.3374 21.8683 15.4274 21.8683 15.5074 21.8573C16.2874 21.7583 16.9074 21.2273 17.1374 20.4773L21.9074 4.5083C22.1174 3.8283 21.9274 3.0883 21.4274 2.5783Z" fill="currentColor"></path>
    <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M3.01049 16.8079C2.81849 16.8079 2.62649 16.7349 2.48049 16.5879C2.18749 16.2949 2.18749 15.8209 2.48049 15.5279L3.84549 14.1619C4.13849 13.8699 4.61349 13.8699 4.90649 14.1619C5.19849 14.4549 5.19849 14.9299 4.90649 15.2229L3.54049 16.5879C3.39449 16.7349 3.20249 16.8079 3.01049 16.8079ZM6.77169 18.0003C6.57969 18.0003 6.38769 17.9273 6.24169 17.7803C5.94869 17.4873 5.94869 17.0133 6.24169 16.7203L7.60669 15.3543C7.89969 15.0623 8.37469 15.0623 8.66769 15.3543C8.95969 15.6473 8.95969 16.1223 8.66769 16.4153L7.30169 17.7803C7.15569 17.9273 6.96369 18.0003 6.77169 18.0003ZM7.02539 21.5683C7.17139 21.7153 7.36339 21.7883 7.55539 21.7883C7.74739 21.7883 7.93939 21.7153 8.08539 21.5683L9.45139 20.2033C9.74339 19.9103 9.74339 19.4353 9.45139 19.1423C9.15839 18.8503 8.68339 18.8503 8.39039 19.1423L7.02539 20.5083C6.73239 20.8013 6.73239 21.2753 7.02539 21.5683Z" fill="currentColor"></path>
    </svg>`

const columns = [
    {
        title: 'Domain',
        data: 'domain',
    },
    {
        title: 'Title',
        data: 'title',
    },
    {
        title: 'Email',
        data: 'email',
    },
    {
        title: 'Status',
        data: 'status',
        render: (data) => {
            return '<span class="badge ' + data.color + '">' + data.status + '</span>'
        },
    },
    {
        title: 'Action',
        data: 'id',
        render: (data) => {
            const viewButton = data.domain
                ? '<a class="btn btn-icon btn-success view-icon opacity-10 tooltip" data-target="' +
                  data.domain +
                  '"><span class="btn-inner">' +
                  viewIcon +
                  '</span><span class="tooltiptext">View Creative</span></a>'
                : ''

            return (
                '<div class="flex align-items-center list-campaign-action"> <a class="btn btn-icon btn-primary edit-icon opacity-10 tooltip" data-target="' +
                data.id +
                '"><span class="btn-inner">' +
                editIcon +
                '</span><span class="tooltiptext">Edit Creative</span></a> ' +
                // <a class="btn btn-icon btn-danger remove-icon opacity-10 tooltip" data-target="' + data.id + '"><span class="btn-inner">' + removeIcon + '</span><span class="tooltiptext">Delete Landing Page</span></a> '' +
                viewButton +
                '</div>'
            )
        },
    },
]

const LandingPageList = memo(() => {
    const { guide } = useContext(GuideContext)
    const params = useParams()
    const campaignId = params.id
    const [landingPageData, setLandingPageData] = useState([])
    const [hasCreatives, setHasCreatives] = useState(null)
    const [notificationShow, setNotificationShow] = useState(true)
    const navigate = useNavigate()
    const location = useLocation()

    const guidedRef = useRef(false)

    const { data, loading, refetch } = useQuery(GET_LANDING_PAGES, {
        variables: { where: { campaign: { id: campaignId } } },
        onCompleted: (data) => {
            setNotificationShow(true)
            setHasCreatives(data?.landingPages.length > 0 || false)
        }
    })

    const campaign = useQuery(GET_CAMPAIGN, {
        variables: { where: { id: campaignId } },
    })

    const getLandingPageStatus = (landingPage) => {
        return landingPage.status === 'ACTIVE'
            ? { status: 'Active', color: 'bg-primary' }
            : { status: 'Inactive', color: 'bg-danger' }
    }

    useEffect(() => {
        const landingPagesData = []
        if (data) {
            _.each(data.landingPages, (landingPage) => {
                const { status, color } = getLandingPageStatus(landingPage)

                landingPagesData.push({
                    id: {
                        id: landingPage.id,
                        domain: landingPage.status === 'ACTIVE' ? landingPage.domain : null,
                    },
                    domain: landingPage.domain,
                    title: landingPage.title,
                    email: landingPage.email,
                    status: {
                        status: status,
                        color: color,
                    },
                })
            })
        }

        setLandingPageData(landingPagesData)
    }, [data])

    const onClickEditButton = (event) => {
        const landingPageId = event.currentTarget.getAttribute('data-target')
        navigate(`/landing-page/${landingPageId}/edit`)
    }

    const onClickViewButton = (event) => {
        const url = `https://${event.currentTarget.getAttribute('data-target')}.${
            process.env.REACT_APP_LANDING_PAGE_DOMAIN
        }`
        window.open(url, '_blank', 'noreferrer')
    }

    const navigateToCreateLandingPage = () => {
        navigate('/campaigns/' + campaignId + '/landing-page/new')
    }

    useEffect(() => {
        const editButtons = document.getElementsByClassName('edit-icon')
        _.each(editButtons, (element) => {
            element.addEventListener('click', onClickEditButton)
        })
        const viewButtons = document.getElementsByClassName('view-icon')
        _.each(viewButtons, (element) => {
            element.addEventListener('click', onClickViewButton)
        })
        return () => {
            _.each(editButtons, (element) => {
                element.removeEventListener('click', onClickEditButton)
            })
            _.each(viewButtons, (element) => {
                element.removeEventListener('click', onClickViewButton)
            })
        }
    })

    useEffect(() => {
        if (location.state?.refetch) {
            setNotificationShow(false)
            refetch()
        }
    }, [location.state?.refetch, refetch])

    useEffect(() => {
        if (guide === 'on') {
            if (hasCreatives !== null && notificationShow) {
                if (!hasCreatives && !guidedRef.current) {
                    showNotification(
                        <div>
                            <p>Here, you can build your campaign creatives. They are essential for media buying.</p>
                            <p>Please click "Add Creative" on the top right corner or the button "Continue" below to
                                start the process.'</p>
                        </div>,
                        'info',
                        false,
                        'Continue',
                        navigateToCreateLandingPage,
                        false
                    )
                } else if (hasCreatives) {
                    showNotification(
                        <div>
                            <p>Great job! You have creative(s).</p>
                            <p>To add additional creative, click the 'X' button in the top-right corner of this
                                message.</p>
                            <p>To proceed, click the "Continue" button.</p>
                        </div>,
                        'info',
                        false,
                        'Continue',
                        () => navigate('/campaigns', {state: {refetch: true}}),
                        true
                    )
                }
                guidedRef.current = true
            }
        }
    }, [hasCreatives, guidedRef, notificationShow, guide])

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">
                                        <i className="icon">
                                            <svg
                                                width="25"
                                                className="icon-25"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    opacity="0.4"
                                                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2"
                                                    fill="currentColor"
                                                ></path>
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M8.07999 6.64999V6.65999C7.64899 6.65999 7.29999 7.00999 7.29999 7.43999C7.29999 7.86999 7.64899 8.21999 8.07999 8.21999H11.069C11.5 8.21999 11.85 7.86999 11.85 7.42899C11.85 6.99999 11.5 6.64999 11.069 6.64999H8.07999ZM15.92 12.74H8.07999C7.64899 12.74 7.29999 12.39 7.29999 11.96C7.29999 11.53 7.64899 11.179 8.07999 11.179H15.92C16.35 11.179 16.7 11.53 16.7 11.96C16.7 12.39 16.35 12.74 15.92 12.74ZM15.92 17.31H8.07999C7.77999 17.35 7.48999 17.2 7.32999 16.95C7.16999 16.69 7.16999 16.36 7.32999 16.11C7.48999 15.85 7.77999 15.71 8.07999 15.74H15.92C16.319 15.78 16.62 16.12 16.62 16.53C16.62 16.929 16.319 17.27 15.92 17.31Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </i>{' '}
                                        Creatives - {campaign.data?.campaigns[0]?.name}
                                    </h4>
                                </div>
                                <Button
                                    variant="success"
                                    size="sm"
                                    onClick={() => navigate(`/campaigns/${params.id}/landing-page/new/`)}
                                >
                                    <svg
                                        width="32"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon-24"
                                        height="32"
                                    >
                                        <path
                                            opacity="0.4"
                                            d="M16.6667 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0622 3.92 22 7.33333 22H16.6667C20.0711 22 22 20.0622 22 16.6667V7.33333C22 3.92889 20.0711 2 16.6667 2Z"
                                            fill="currentColor"
                                        ></path>
                                        <path
                                            d="M15.3205 12.7083H12.7495V15.257C12.7495 15.6673 12.4139 16 12 16C11.5861 16 11.2505 15.6673 11.2505 15.257V12.7083H8.67955C8.29342 12.6687 8 12.3461 8 11.9613C8 11.5765 8.29342 11.2539 8.67955 11.2143H11.2424V8.67365C11.2824 8.29088 11.6078 8 11.996 8C12.3842 8 12.7095 8.29088 12.7495 8.67365V11.2143H15.3205C15.7066 11.2539 16 11.5765 16 11.9613C16 12.3461 15.7066 12.6687 15.3205 12.7083Z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>{' '}
                                    Add Creative
                                </Button>
                            </Card.Header>
                            <Card.Body className="px-0">
                                <div className="table-responsive">
                                    <DataTable data={landingPageData} className="table-striped" columns={columns} />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}

            <Notification/>
        </Fragment>
    )
})

LandingPageList.displayName = 'LandingPageList'
export default LandingPageList
