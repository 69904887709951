import React, { Fragment, useEffect, useRef, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'
import { GuideContext } from '../../context/guide-context'
import { useOAuth2 } from '../../utils/oauth2-hook'
import { CAMPAIGN_FIELDS } from '../../graphql/campaign-fragments'
import { Row } from 'react-bootstrap'
import Card from '../../components/common/Card'
import PlatformCard from '../../components/campaigns/PlatformCard'
import Notification from '../../components/partials/Notification'
import Loader from '../../layouts/components/Loader'
import { showNotification, askConfirmation } from '../../utils/notifications'
import { logos } from '../../utils/platform-logos'
import _ from 'lodash'

const GET_CAMPAIGN = gql`
    ${CAMPAIGN_FIELDS}
    query Campaigns($where: CampaignWhere) {
        campaigns(where: $where) {
            ...CampaignFields
            platforms {
                id
            }
            platformsAggregate {
                count
            }
            landingPagesAggregate {
                count
            }
        }
    }
`

const GET_PLATFORMS = gql`
    query Platforms($options: PlatformOptions) {
        platforms(options: $options) {
            id
            name
            authorizeUrl
            clientId
            scope
            extra
            status
            credentials {
                accessToken
            }
        }
    }
`

const CONNECT_CAMPAIGN_TO_PLATFORM = gql`
    mutation ConnectCampaignToPlatformWithCredentials($oAuthPlatformInput: OAuthPlatformCredentialsInput) {
        connectCampaignToPlatformWithCredentials(oAuthPlatformInput: $oAuthPlatformInput) {
            id
            platforms {
                id
            }
        }
    }
`

const CONNECT_CAMPAIGN_TO_PLATFORM_WITHOUT_AUTH = gql`
    ${CAMPAIGN_FIELDS}
    mutation ConnectCampaignToPlatform($oAuthPlatformInput: OAuthPlatformInput) {
        connectCampaignToPlatform(oAuthPlatformInput: $oAuthPlatformInput) {
            ...CampaignFields
            platforms {
                id
            }
        }
    }
`

const DISCONNECT_CAMPAIGN_FROM_PLATFORM = gql`
    mutation DisconnectCampaignFromPlatform($revokeOAuthPlatformInput: RevokeOAuthPlatformInput) {
        disconnectCampaignFromPlatform(revokeOAuthPlatformInput: $revokeOAuthPlatformInput) {
            id
            platforms {
                id
            }
        }
    }
`

const Platforms = () => {
    const params = useParams()
    const { guide } = useContext(GuideContext)
    const campaignId = params.id
    const navigate = useNavigate()

    const guidedRef = useRef(false)
    const guidedRefCon = useRef(false)

    const [run, setRun] = useState(true)
    const [connected, setConnected] = useState(null)
    const [hasCreatives, setHasCreatives] = useState(null)
    const [steps] = useState([
        {
            target: '.platform-step-0',
            content: 'If you want your campaign to be integrated with this platform, click "Connect Now" button and follow the prompts on the screen',
        }
    ])

    const campaigns = useQuery(GET_CAMPAIGN, {
        variables: { where: { id: campaignId } },
        onCompleted: (data) => {
            let result = false
            let hasCreatives = false
            data?.campaigns.forEach((campaign) => {
                if (campaign.platformsAggregate.count > 2) {
                    result = true
                }
                if (campaign.landingPagesAggregate.count > 0) {
                    hasCreatives = true
                }
            })
            setConnected(result)
            setHasCreatives(hasCreatives)
        }
    })

    const platforms = useQuery(GET_PLATFORMS, {
        variables: { options: { sort: [{ order: 'ASC' }] } },
    })

    const connectCampaignMutationHandlers = {
        onError({ graphQLErrors }) {
            showNotification(graphQLErrors[0]?.message, 'error')
        },
        onCompleted: () => {
            platforms.refetch()
            showNotification('The platform has been connected successfully!', 'success')
        },
    }

    const [connectCampaignToPlatform] = useMutation(CONNECT_CAMPAIGN_TO_PLATFORM, connectCampaignMutationHandlers)
    const [connectCampaignToPlatformWithoutAuth] = useMutation(
        CONNECT_CAMPAIGN_TO_PLATFORM_WITHOUT_AUTH,
        connectCampaignMutationHandlers
    )

    const clickConnect = async (platformId) => {
        const selectedPlatform = _.filter(platforms.data.platforms, (platform) => {
            return platform.id === platformId
        })?.[0]

        const platformRequiresAuth = !!selectedPlatform.authorizeUrl

        const platformCredentials = selectedPlatform.credentials

        if (platformRequiresAuth && !platformCredentials) return getAuth(selectedPlatform)

        if (platformCredentials)
            return await connectCampaignToPlatform({
                variables: {
                    oAuthPlatformInput: {
                        id: selectedPlatform.id,
                        campaignId: campaignId,
                    }
                },
                onCompleted: (data) => {
                    let result = false
                    if (data?.connectCampaignToPlatformWithCredentials.platforms.length > 2) {
                        result = true
                    }
                    guidedRefCon.current = null
                    setConnected(result)
                }
            })
        return await connectCampaignToPlatformWithoutAuth({
            variables: {
                oAuthPlatformInput: {
                    id: selectedPlatform.id,
                    campaignId: campaignId
                }
            },
            onCompleted: (data) => {
                let result = false
                if (data?.connectCampaignToPlatform.platforms.length > 2) {
                    result = true
                }
                guidedRefCon.current = null
                setConnected(result)
            }
        })
    }

    const revokePlatform = (args) => {
        if (args?.platformId) {
            disconnectCampaignFromPlatform({
                variables: {
                    revokeOAuthPlatformInput: {
                        id: args.platformId,
                        campaignId: campaignId,
                    },
                },
            })
        }
    }

    const clickDisconnect = (platformId, platformName) => {
        askConfirmation(
            `You are going to disconnect ${platformName} from this Campaign`,
            'Disconnect',
            revokePlatform,
            { platformId: platformId }
        )
    }

    const { getAuth } = useOAuth2({
        campaignId: campaignId,
        redirectUri: `${document.location.origin}/callback`,
        responseType: 'code',
        extraQueryParameters: {},
    })

    const [disconnectCampaignFromPlatform] = useMutation(DISCONNECT_CAMPAIGN_FROM_PLATFORM, {
        onError({ graphQLErrors }) {
            showNotification(graphQLErrors[0]?.message, 'error')
        },
        onCompleted: () => {
            platforms.refetch()
            showNotification('The platform has been disconnected successfully!', 'success')
        },
    })

    const nextStep = () => {
        navigate('/campaigns/' + campaignId + '/landing-pages/')
    }

    useEffect(() => {
        if (connected !== null && guide === 'on') {
            if (!connected && !guidedRef.current) {
                showNotification(
                    <div>
                        <p>Please authenticate the platforms you wish to integrate with using your unique username and password.</p>
                        <p>We recommend authenticating with at least three platforms, preferably Instagram, Twitter, and YouTube.</p>
                        <p>Follow the provided instructions to complete the integration.</p>
                    </div>,
                    'info',
                    false,
                    'Continue',
                    null,
                    false
                )
                guidedRef.current = true
            }
        }
    }, [connected])

    useEffect(() => {
        if (hasCreatives !== null && guide === 'on') {
            if (!hasCreatives && connected && !guidedRefCon.current) {
                showNotification(
                    <div>
                        <p>Great job! You are successfully connected to the platforms.</p>
                        <p>To connect additional platforms, click the 'X' button in the top-right corner of this message.</p>
                        <p>To proceed, click the "Continue" button.</p>
                    </div>,
                    'info',
                    false,
                    'Continue',
                    () => navigate('/campaigns/' + campaignId + '/landing-pages'),
                    true
                )
                guidedRefCon.current = true
            }
        }
    }, [hasCreatives, guidedRefCon.current, connected, guide])

    useEffect(() => {
        const checkElements = setInterval(() => {
            const allTargetsMounted = steps.every(step => document.querySelector(step.target))
            if (allTargetsMounted) {
                clearInterval(checkElements)
                setRun(true)
            }
        }, 100)
    }, [steps])

    return (
        <Fragment>
            {campaigns.loading || platforms.loading ? (
                <Loader />
            ) : (
                <Card>
                    <Card.Header className="align-items-center flex-wrap">
                        <div className="header-title">
                            <h4 className="card-title">
                                <i className="icon">
                                    <svg
                                        className="icon-25"
                                        width="25"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            opacity="0.4"
                                            d="M12.02 2C6.21 2 2 6.74 2 12C2 13.68 2.49 15.41 3.35 16.99C3.51 17.25 3.53 17.58 3.42 17.89L2.75 20.13C2.6 20.67 3.06 21.07 3.57 20.91L5.59 20.31C6.14 20.13 6.57 20.36 7.081 20.67C8.541 21.53 10.36 21.97 12 21.97C16.96 21.97 22 18.14 22 11.97C22 6.65 17.7 2 12.02 2Z"
                                            fill="currentColor"
                                        ></path>
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M11.9807 13.2901C11.2707 13.2801 10.7007 12.7101 10.7007 12.0001C10.7007 11.3001 11.2807 10.7201 11.9807 10.7301C12.6907 10.7301 13.2607 11.3001 13.2607 12.0101C13.2607 12.7101 12.6907 13.2901 11.9807 13.2901ZM7.37033 13.2901C6.67033 13.2901 6.09033 12.7101 6.09033 12.0101C6.09033 11.3001 6.66033 10.7301 7.37033 10.7301C8.08033 10.7301 8.65033 11.3001 8.65033 12.0101C8.65033 12.7101 8.08033 13.2801 7.37033 13.2901ZM15.3105 12.0101C15.3105 12.7101 15.8805 13.2901 16.5905 13.2901C17.3005 13.2901 17.8705 12.7101 17.8705 12.0101C17.8705 11.3001 17.3005 10.7301 16.5905 10.7301C15.8805 10.7301 15.3105 11.3001 15.3105 12.0101Z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>
                                </i>{' '}
                                Campaign Platforms Settings - {campaigns.data.campaigns[0].name}
                            </h4>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className="row-cols-xl-4 row-cols-md-2 row-cols-1">
                            {platforms.data.platforms
                                .filter(platform => platform.status === 'ACTIVE')
                                .map((platform, index) => {
                                return (
                                    <PlatformCard
                                        key={platform.id}
                                        className={'platform-step-' + index}
                                        img={logos[platform.name]}
                                        header={platform.name}
                                        connected={
                                            _.find(campaigns.data.campaigns[0].platforms, { id: platform.id }) !==
                                            undefined
                                        }
                                        onConnect={() => clickConnect(platform.id)}
                                        onRevoke={() => clickDisconnect(platform.id, platform.name)}
                                    />
                                )
                            })}
                        </Row>
                     </Card.Body>
                </Card>
            )}
            <Notification />
        </Fragment>
    )
}

export default Platforms
