import React, { useState, useContext, Fragment, useRef, forwardRef, useImperativeHandle } from 'react'
import { useNavigate } from 'react-router-dom'
import { SettingsContext } from '../../context/settings-context'
import { GuideContext } from '../../context/guide-context'
import { gql, useMutation } from '@apollo/client'
import { USER_PAYMENT_FIELDS } from '../../graphql/user-fragments'
import { Form, Button, Spinner, Alert } from 'react-bootstrap'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import Card from '../common/Card'
import ShowErrors from '../partials/ShowErrors'

const UPDATE_PAYMENT_METHOD = gql`
    ${USER_PAYMENT_FIELDS}
    mutation UpdatePaymentMethod($paymentMethodInput: PaymentMethodInput) {
        updatePaymentMethod(paymentMethodInput: $paymentMethodInput)  {
            ...UserPaymentFields
        }
    }`

const PaymentDetails = forwardRef(({ userData, data }, ref) => {
    const elements = useElements()
    const stripe = useStripe()

    const cardElementRef = useRef(null)
    const { theme } = useContext(SettingsContext)
    const { guide } = useContext(GuideContext)
    const [edit, setEdit] = useState(false)
    const [errors, setError] = useState([])
    const [processing, setProcessing] = useState(false)
    const [paymentData, setPaymentData] = useState(data)
    const navigate = useNavigate()

    useImperativeHandle(ref, () => ({
        focusCardNumber: () => {
            if (cardElementRef.current) {
                cardElementRef.current.focus()
            }
        },
    }))

    const options = {
        hidePostalCode: true,
        style: {
            base: {
                fontSize: '16px',
                fontFamily: 'Poppins',
                color: theme === 'dark' ? '#FFFFFF' : '#8A92A6',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#c03221',
            }
        }
    }

    const billingDetails = {
        name: `${userData.firstName} ${userData.lastName}`,
        address: {
            city: userData.city,
            country: userData.country,
            line1: userData.addressLine1,
            line2: userData.addressLine2,
            postal_code: userData.postalCode,
            state: userData.state
        },
        email: userData.email,
        phone: userData.phone
    }

    const submitCardDetails = (event) => {
        event.preventDefault()

        if (!stripe || !elements) {
            return
        }

        const cardElement = elements.getElement(CardElement)

        stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: billingDetails,
        })
        .then( async(result) => {
            if (result.error) {
                setError([result.error])
            } else {
                // Process the Card.
                const cardData = {
                    cardBrand: result.paymentMethod.card.brand,
                    cardExpMonth: result.paymentMethod.card.exp_month,
                    cardExpYear: result.paymentMethod.card.exp_year,
                    cardLastDigits: result.paymentMethod.card.last4,
                    id: result.paymentMethod.id
                }
                setError([])
                setProcessing(true)
                await updatePaymentMethod({ variables: { paymentMethodInput: cardData } })
            }
        })
    }

    const [updatePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD, {
        onError({ graphQLErrors}) {
            setError(graphQLErrors)
            setProcessing(false)
        },
        refetchQueries: ['Users'],
        onCompleted: (response) => {
            setProcessing(false)
            setError([])
            setPaymentData(response.updatePaymentMethod)
            setEdit(false)

            setTimeout(() => {
                if (data?.campaignsAggregate?.count === 0 && guide === 'on') {
                    navigate('/campaigns/new')
                }
            }, 1000)
        }
    })

    return (
        <Card>
            <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                    <h4 className="card-title">
                        <i className="icon">
                            <svg className="icon-25" width="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="32">
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.9964 8.37513H17.7618C15.7911 8.37859 14.1947 9.93514 14.1911 11.8566C14.1884 13.7823 15.7867 15.3458 17.7618 15.3484H22V15.6543C22 19.0136 19.9636 21 16.5173 21H7.48356C4.03644 21 2 19.0136 2 15.6543V8.33786C2 4.97862 4.03644 3 7.48356 3H16.5138C19.96 3 21.9964 4.97862 21.9964 8.33786V8.37513ZM6.73956 8.36733H12.3796H12.3831H12.3902C12.8124 8.36559 13.1538 8.03019 13.152 7.61765C13.1502 7.20598 12.8053 6.87318 12.3831 6.87491H6.73956C6.32 6.87664 5.97956 7.20858 5.97778 7.61852C5.976 8.03019 6.31733 8.36559 6.73956 8.36733Z" fill="currentColor">
                                </path><path opacity="0.4" d="M16.0374 12.2966C16.2465 13.2478 17.0805 13.917 18.0326 13.8996H21.2825C21.6787 13.8996 22 13.5715 22 13.166V10.6344C21.9991 10.2297 21.6787 9.90077 21.2825 9.8999H17.9561C16.8731 9.90338 15.9983 10.8024 16 11.9102C16 12.0398 16.0128 12.1695 16.0374 12.2966Z" fill="currentColor"></path>
                                <circle cx="18" cy="11.8999" r="1" fill="currentColor"></circle>
                            </svg>
                        </i>{' '}
                        Payment Details
                    </h4>
                </div>
            </Card.Header>
            <Card.Body>
                { paymentData.paymentMethodId && (
                    <div className="card credit-card-widget" data-aos="fade-up" data-aos-delay="900">
                        <div className="pb-4 border-0 card-header">
                            <div className="p-4 border border-white rounded primary-gradient-card">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h5 className="font-weight-bold">{paymentData.cardBrand.toUpperCase()}</h5>
                                        <p className="mb-0">PREMIUM ACCOUNT</p>
                                    </div>
                                    <div className="master-card-content">
                                        <svg className="master-card-1" width="60" height="60" viewBox="0 0 24 24">
                                            <path fill="#ffffff" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                        </svg>
                                        <svg className="master-card-2" width="60" height="60" viewBox="0 0 24 24">
                                            <path fill="#ffffff" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="my-4">
                                    <div className="card-number">
                                        <span className="fs-5 me-2">****</span>
                                        <span className="fs-5 me-2">****</span>
                                        <span className="fs-5 me-2">****</span>
                                        <span className="fs-5">{paymentData.cardLastDigits}</span>
                                    </div>
                                </div>
                                <div className="mb-2 d-flex align-items-center justify-content-between">
                                    <p className="mb-0">Card holder</p>
                                    <p className="mb-0">Expire Date</p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <h6>{paymentData.firstName} {paymentData.lastName}</h6>
                                    <h6 className="ms-5">{paymentData.cardExpMonth}/{paymentData.cardExpYear}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                    <Fragment>
                        {!data.country && (
                            <Alert variant="info alert-left alert-dismissible fade show d-flex align-items-center" role="alert" dismissible style={{marginBottom: '20px'}}>
                                <div>To add payment details, please enter your valid address and click Update button.</div>
                            </Alert>
                        )}

                        {
                            edit ? (
                                <Form onSubmit={submitCardDetails}>
                                    <Form.Group className="col-md-12 form-group">
                                        <CardElement
                                            options={options}
                                            onReady={(el) => (cardElementRef.current = el)}
                                        />
                                    </Form.Group>
                                    <div className="pb-3">
                                        <ShowErrors errors={errors}/>
                                    </div>
                                    <Button type="submit" variant="btn btn-primary" disabled={processing}>
                                        { processing ? (
                                            <Fragment>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                {' '}Updating...
                                            </Fragment>
                                        ) : (
                                            <Fragment>Update</Fragment>
                                        )}
                                    </Button>{' '}
                                    {' '}
                                    <Button type="button" variant="btn btn-danger" disabled={processing} onClick={() => setEdit(false)}>Cancel</Button>
                                </Form>
                            ) : (
                                <Button type="button" variant="btn btn-primary" className="step-4" onClick={() => setEdit(true)} disabled={!data.country}>
                                    { paymentData.paymentMethodId ? 'Edit' : 'Add'}
                                </Button>
                            )
                        }
                    </Fragment>

            </Card.Body>
        </Card>
    )
})

export default PaymentDetails