import { Navigate } from 'react-router-dom'
import Login from '../pages/Login'
import SignUp from '../pages/SignUp'
import ConfirmEmail from '../pages/ConfirmEmail'
import ResetPassword from '../pages/ResetPassword'
import UpdatePassword from '../pages/UpdatePassword'

export const UnauthenticatedUserRoutes = [
    {
        path: 'login',
        element: <Login />,
    },
    {
        path: 'signup',
        element: <SignUp />,
    },
    {
        path: 'confirm-email',
        element: <ConfirmEmail />,
    },
    {
        path: 'reset-password',
        element: <ResetPassword />,
    },
    {
        path: 'update-password',
        element: <UpdatePassword />,
    },
    {
        path: '*',
        element: <Navigate to="login" replace />,
    },
]
