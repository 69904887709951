import React, { memo, Fragment, useContext, useState } from 'react'
import { Image } from 'react-bootstrap'
import { AuthContext } from '../../context/auth-context'
import { gql, useQuery } from '@apollo/client'
import Loader from '../../layouts/components/Loader'

const GET_USER = gql`
    query Users($where: UserWhere) {
        users(where: $where) {
            id
            email
            firstName
            lastName
            logoImage
        }
    }
`

const SidebarProfileCard = memo(() => {
    const { user } = useContext(AuthContext)

    const { data, loading } = useQuery(GET_USER, {
        variables: { where: { id: user.id } },
        onCompleted: (data) => {
            if (data?.users[0]?.logoImage) {
                setProfileImage(data.users[0].logoImage)
            }
        }
    })
    const [profileImage, setProfileImage] = useState(data?.users[0]?.logoImage || 'logo.png')

    const name = () => {
        return data.users[0].firstName + ' ' + data.users[0].lastName
    }

    return (
        <Fragment>
            {loading ? (
                <div>
                    <Loader fullScreen={false} />
                </div>
            ) : (
                <div className="sidebar-profile-card mt-3">
                    <div className="sidebar-profile-body">
                        <div className="mb-3 d-flex justify-content-center">
                            <div className="rounded-3 border border-primary p-2">
                                <Image
                                    src={`${process.env.REACT_APP_S3_BUCKET_URL}${profileImage ? profileImage : 'logo.png'}`}
                                    alt="User Logo"
                                    onError={() => setProfileImage('logo.png')}
                                    className="img-fluid rounded"
                                />
                            </div>
                        </div>
                        <div className="sidebar-profile-detail">
                            <h6 className="sidebar-profile-name">{name()}</h6>
                            <span className="sidebar-profile-username">{data.users[0].email}</span>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    )
})

export default SidebarProfileCard
